
import { defineComponent } from 'vue';
import ViewMixin from '@/mixins/ViewMixin';
import Panel from '@/components/layout/Panel.vue';

export default defineComponent({
    name: 'Maps',
    mixins: [ViewMixin],
    components: {
        Panel,
    },
    created(): void {
        this.setPageTitle([this.$t('maps'), this.$t('ingame')]);
    },
});
